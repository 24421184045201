:root {
  --foreground-rgb: rgba(20, 20, 20, 1);
  --background-start-rgb: rgba(20, 20, 20, 1);
  --background-end-rgb: rgba(20, 20, 20, 1);
  // back ground color
  --app-home-bg-rgb: #000;
  --app-line-bg-rgb: rgba(242, 242, 242, 0.3);
  --app-line-color: rgba(242, 242, 242, 0.3);
  --app-input-bg-rgb: #333;
  --app-home-gpt-bg-rgb: rgba(208, 58, 58, 0.1);
  --app-home-gpt-a-rgb: rgba(208, 58, 58, 1); //#E1AE72
  --app-share-bg-rgb: #333;
  // title color
  --app-text-color: rgb(255, 255, 255);
  --app-text-60-color: rgba(255, 255, 255, 0.6);
  --app-text-30-color: rgba(255, 255, 255, 0.3);

  //
  --other-text-color: rgba(20, 20, 20, 1);
  --other-bg-color: #fff;

  // link
  --app-link-color: #369eff;

  --app-primary-color: #9873ff;

  // .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  //   color: rgba(255, 255, 255, 0.3);
  // }

  // .ant-pagination .ant-pagination-prev button {
  //   color: white;
  // }
  // .ant-pagination .ant-pagination-next button {
  //   color: white;
  // }

  // .ant-pagination .ant-pagination-item a {
  //   color: white;
  // }

  // .ant-pagination .ant-pagination-item-active a {
  //   color: #e1ae72;
  // }

  // .ant-pagination
  //   .ant-pagination-jump-next
  //   .ant-pagination-item-container
  //   .ant-pagination-item-ellipsis {
  //   color: white;
  // }

  // .ant-pagination
  //   .ant-pagination-jump-prev
  //   .ant-pagination-item-container
  //   .ant-pagination-item-ellipsis {
  //   color: white;
  // }

  // 
  .next-antd-pagination .next-antd-pagination-item a {
    display: block;
    padding: 0 6px;
    color: white;
}

  .next-antd-rate-star-second {
    color: #666;
  }

  .linearSelect1 {
    background: linear-gradient(160deg, #725CFF, rgb(194, 208, 251));
    // background: linear-gradient( 180deg, #4A4395 0%, #2D2C2C 49%);
    border-radius: 12px;
    border: 0px;
  }
  // .linearSelect1 {
  //   background: linear-gradient(180deg, rgba(114, 92, 255, 1), rgba(114, 92, 255, 0.5));
  //   border-radius: 12px;
  //   border: 0px;
  // }

  .next-antd-float-btn-default .next-antd-float-btn-body {
    background-color: var(--app-primary-color);
  }
  .next-antd-float-btn-default
    .next-antd-float-btn-body
    .next-antd-float-btn-content
    .next-antd-float-btn-description {
    color: #fff;
  }

  .next-antd-modal-content {
    padding: 0px !important;
  }

}

